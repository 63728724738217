import './App.css';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import Homepage from './homepage/Home';

function App() {
  return (
    <>
    <Homepage></Homepage> 
    </>
  );
}

export default App;
