import '../App';
import React from 'react';
import mainlogo from '../Images/AutoBodyRepairPaintService.jpg'

function Homepage() {
  return (
    <>
    <div className="Homepage">
    </div>
    <div style ={{textAlign:"center"}}>
        <img src={mainlogo}  alt ="AutoBodyRepairPaintService" style= {{ height: "Auto", width:"auto",  border:"none"}}>
        </img>
    </div>
    </>
    
  );
}

export default Homepage;